.form {
  width: 100%;
  padding: 50px;
  background: white;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}
.form svg {
  position: absolute;
  top: 0;
  left: 0;
  fill: rgb(255 241 223);
}

.form .form-main img {
  width: 580px;
  max-width: 100%;
  height: auto;
  padding: 0 10px;
  border-radius: 100px;
}
.form-container {
  width: 600px;
  max-width: 100%;
  padding: 0 10px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.form-main {
  width: 1290px;
  max-width: 95%;
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-around;
  z-index: 1;
}

.form-control {
  display: block;
  width: 100% !important;
  padding: 0.375rem 0.75rem;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border-radius: 0.375rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.react-datepicker-wrapper {
  width: 100%;
}

.form form input,
.form form select {
  height: 56px !important;
  border: 1px solid #1e1e1e;
  color: #6c757d;
  border-radius: 0;
  /* padding: 22px 24px; */
  margin-bottom: 20px;
}
.form form {
  width: 100%;
  padding-top: 20px;
  padding-bottom: 20px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  max-width: 400px;
}

.form-block {
  width: 100%;
}

.form-container h2 {
  font-weight: 500;
  font-size: 30px;
  line-height: 38px;
  text-transform: uppercase;
}

.form-container span {
  margin: 24px 0 8px;
  font-size: 16px;
  line-height: 20px;
  max-width: 394px;
}

.react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle {
  fill: #f0f0f0;
  color: #f0f0f0;
  display: flex;
  margin-top: -16px;
}

@media screen and (max-width: 1250px) {
  .form {
    height: auto;
    padding: 0 0 20px 0;
  }
  .form-container {
    text-align: center;
    margin-top: 40px;
  }
}

@media screen and (max-width: 625px) {
  .form .form-main img {
    margin-bottom: 35px;
  }
  .form-container h2 {
    font-size: 20px;
  }
}

.form-control:disabled {
  background-color: white !important;
}

/* Stiluri pentru Popup - MODIFICATE */
.popup-overlay {
  position: fixed; /* Stă pe loc indiferent de scroll */
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6); /* Fundal semi-transparent */
  display: flex;
  justify-content: center; /* Centrează conținutul orizontal */
  align-items: center; /* Centrează conținutul vertical */
  z-index: 1000; /* Asigură că e deasupra altor elemente */
  padding: 15px; /* Adaugă puțin padding la overlay pentru ecrane mici */
}

.popup-content {
  height: fit-content;
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
  /* Lățimea va fi determinată de conținut, până la o limită */
  max-width: 400px; /* Limită lățimea maximă */

  display: flex;
  flex-direction: column; /* Aranjează elementele copil vertical */
  align-items: center; /* Centrează elementele copil orizontal */
  /* Eliminăm paddingul general de aici, îl punem pe text */
  padding: 20px 20px; /* Păstrăm un padding minim sus/jos și pe laterale pt container */
}

.contact-info-form {
  display: flex;
  align-items: center;
  justify-content: center;
  color: rgb(0, 0, 0);
}

.popup-content p {
  /* Adăugăm padding specific textului */
  padding: 20px;
  /* Centram textul în interiorul paragrafului */
  text-align: center;
  /* Spațiu sub text, înainte de butoane */
  margin: 0 0 20px 0; /* Resetăm marginile default și adăugăm doar jos */
  font-size: 1.1rem;
  color: #333;
  /* Asigură împărțirea cuvintelor dacă textul e lung */

  width: 100%; /* Face paragraful să ocupe lățimea containerului flex */
}

.popup-buttons {
  display: flex;
  justify-content: center; /* Centrează butoanele în containerul lor */
  gap: 15px; /* Spațiu între butoane */
  width: 100%; /* Asigură că div-ul butoanelor ocupă spațiul disponibil */
}

.popup-buttons button {
  padding: 10px 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1rem;
  min-width: 80px;
  background-color: #f8f8f8; /* Fundal ușor gri */
  transition: background-color 0.2s ease, border-color 0.2s ease;
}

/* Stil specific pentru butonul "Da" sau "Închide" */
/* Putem să îl facem puțin mai proeminent dacă dorim */

.popup-buttons button:hover {
  background-color: #e9e9e9;
  border-color: #aaa;
}

/* --- END Stiluri Popup --- */

/* Asigură-te că formularul nu are un z-index mai mare */
#form-element {
  position: relative;
  z-index: 1;
}

.custom-alert {
  max-height: 90vh; /* Max 90% din înălțimea ecranului */
  overflow-y: auto; /* Scroll dacă este nevoie */
  font-size: 12px; /* Reduce dimensiunea fontului */
  padding: 15px; /* Mai puțin padding pentru a economisi spațiu */
}

.custom-alert p {
  margin-bottom: 8px; /* Reduce spațiul dintre paragrafe */
  line-height: 1.4; /* Strânge puțin textul */
}

div:where(.swal2-icon) {
  margin-top: 0 !important;
}

div:where(.swal2-container) button:where(.swal2-styled):where(.swal2-confirm) {
  background-color: #cbcbd0 !important;
  color: rgb(0, 0, 0) !important;
}
